import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/Layout'
import contactData from './../data/contact'

class Contact extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`About Me | ${siteTitle}`}
        />

        <h2>About Me - Miles Robson</h2>
        <br />
        <p>
          Miles, like{' '}
          <a href="https://www.poetryfoundation.org/poems-and-poets/poems/detail/42891">
            Stopping by Woods on a Snowy Evening
          </a>{' '}
          by Robert Frost.
        </p>

        <p>
          I have been doing professional development since 2015, mostly in the
          Salesforce environment.
        </p>

        <p>
          Feel free to send me an{' '}
          <strong>
            <a href="mailto:milesdrobson@gmail.com">
              e-mail @ milesdrobson at gmail.com
            </a>
          </strong>{' '}
          if you want to get a hold of me.
        </p>

        <p>
          I also go by the username <strong>Confirm4Crit</strong> on most
          platforms/Social Media sites, so feel free to look for me where you
          need to.
        </p>
        <h2>Contact</h2>
        <p></p>
        <p>
          <b>email: </b>
          <a href="mailto:milesdrobson@gmail.com" target="_new">
            milesdrobson at gmail.com
          </a>
        </p>
        <p>
          <b>
            <a
              href="https://www.linkedin.com/in/miles-robson-b4124a33/"
              target="_new"
            >
              linkedin
            </a>
          </b>
        </p>
        <p>
          <b>
            <a
              href="https://github.com/confirm4crit"
              target="_new"
            >
              github
            </a>
          </b>
        </p>
        <p>
          <b>
            <a
              href="https://twitter.com/confirm4crit"
              target="_new"
            >
              twitter
            </a>
          </b>
        </p>
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
